














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.mt-9{
    margin-top: 9px;
}
.item-width  {
  
     color:#409EFF;
}
.el-dialog__body{
    padding: 1px 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:0px solid #ccc;
} 
.el-dialog__headerbtn { margin-top:5px;  }
.el-dialog__headerbtn i { background:white;font-size: 20px; }


