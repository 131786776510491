.height {
  word-break: break-all;
  overflow-y: scroll;
}
.operation_show {
  display: none;
}
::v-deep .el-table th {
  padding: 0px ;
}
::v-deep .el-table thead {
  height: 15px !important;
  color: #474646;
  font-size: 12px;
}
::v-deep .el-table td {
  padding: 3px ;
  font-size: 11px;
}
.title {
  font-weight: 600;
  font-size: 14px;
  color: #ccc;
}
.label {
  font-weight: 600;
  font-size: 14px;
  margin-right: 10px;
  color: #351f1f;
}
.siderCls {
  background: #FFF;
  margin: 3px auto;
}
.pub_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
}
.pub_dialog .el-dialog {
  margin: 0 auto !important;
  height: 90%;
  overflow: hidden;
}
.pub_dialog .el-dialog .el-dialog__body {
  position: absolute;
  left: 0;
  top: 54px;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 1;
  overflow: hidden;
  overflow-y: auto;
}
