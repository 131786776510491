














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.height{
    word-break: break-all;
    overflow-y: scroll;
}

.operation_show {
    display: none;
}
::v-deep .el-table {
    th {
        padding: 0px ;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
}

.title{
    font-weight:600;
    font-size:14px;
    color:#ccc
}
.label{
    font-weight:600;
    font-size:14px;
    margin-right:10px;
    color:rgb(53, 31, 31)
}
.siderCls{
    background: #FFF;
    margin: 3px auto;
}    

.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
